@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#body,
#root {
    @apply text-def-primary h-full w-full;
    font-family: "SF Pro Display";
    background: #e5e5e5;
    height: 100% !important;
}

html *:focus {
    outline: none;
}

.field {
    @apply mb-4;
}

input,
textarea {
    @apply p-2 rounded w-full text-def-primary border;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.button,
button,
[type="button"],
[type="reset"],
[type="submit"] {
    @apply bg-white py-2 px-4 rounded w-full flex justify-center items-center cursor-pointer text-sm select-none;
}

textarea:disabled,
input:disabled,
.disabled,
.rc--is-disabled,
.rc--is-disabled .rc__control {
    @apply bg-def-gray cursor-not-allowed;
}

.rc__control {
    border-radius: 0.25rem !important;
    border: 1px solid rgba(20, 33, 61, 0.1) !important;
}

.rc__control--is-focused {
    box-shadow: none !important;
}

.rc__value-container {
    padding: 0.5rem !important;
    margin: 0 !important;
}

.rc__input-container {
    margin: 0 !important;
    padding: 0 !important;
}

.rc__value-container *,
.rc__menu {
    color: #14213d !important;
}
.rc__placeholder{
    color: "#9ca3af" !important;
}
.rdtPicker {
    @apply text-sm;
}

.button:active,
button:active {
    @apply opacity-70;
}

.button *,
button * {
    @apply select-none;
}

.swal-btn {
    @apply mx-2 w-32 rounded;
}

.swal2-image{
    margin: auto !important;
}

.button.secondary,
button.secondary {
    @apply bg-def-secondary text-white;
    box-shadow: 0px 5px 15px rgba(252, 167, 17, 0.5);
}

.button.gray,
button.gray {
    @apply bg-def-gray;
}

.button.primary,
button.primary {
    @apply bg-def-primary text-white;
}

.button.red,
button.red {
    @apply bg-def-red text-white;
}

.cursor {
    @apply hover:opacity-70 cursor-pointer select-none;
}

.link {
    @apply hover:underline cursor-pointer;
}

.error {
    @apply text-def-red;
}

.first {
    display: block !important;
}

.first:first-letter {
    text-transform: uppercase !important;
}

.nav-active svg {
    @apply text-def-secondary;
}

h1.bordered {
    @apply border-def-secondary border-l-4 uppercase pl-1 mb-2;
}

.label {
    @apply first text-sm leading-none mb-2;
}

.react-toggle--checked .react-toggle-track {
    background-color: #fca311 !important;
}
.react-toggle--checked .react-toggle-thumb {
    border-color: #fca311 !important;
}
.react-toggle--focus .react-toggle-thumb {
    box-shadow: none !important;
}

.info{
    @apply my-4 text-sm whitespace-pre-line;
}

.report-table td{
    @apply p-1 border text-sm text-center; 
}

.report-table thead td {
    @apply bg-def-gray;
}

.report-table .focus{
    @apply font-bold bg-def-primary bg-opacity-20;
}

.report-table td.name{
    @apply text-left;
}

.common td, .common th{
    @apply p-1 text-sm;
}

.common th{
    @apply border bg-def-gray;
}

.common td{
    @apply border-b;
}

.planner th, .planner td{
    @apply p-1 text-xs w-24 border;
}

.glass{
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 255, 255, 255, 0.37 );
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 5px );
}

.calendar td{
    @apply p-1 border;
}

.swal2-actions button{
    @apply w-auto;
}